import './PaymentOrdersDashboard.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { debounce } from 'lodash'
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  Feature,
  PaymentOrder,
  PaymentOrderSorter,
  PaymentOrderStatus,
  SorterOrder,
  exportSepa,
  fetchAllVisits,
  fetchCenters,
  fetchPaymentOrders,
  fetchRecruitmentStudies
} from '../../../requests'
import { LocalStorageKey, TableRecord, convertXMLStringToblob, downloadBlob } from '../../../utils'
import { RedirectNoAccessWrapper } from '../../RedirectNoAccessWrapper'
import { UserContext } from '../../auth'
import {
  DatacBulkActionsBar,
  DatacMessage,
  DatacOption,
  DatacTable,
  DatacTableSearchAndFilters,
  DatacTitle,
  rowClickNoPropagateClasses
} from '../../common'
import { MakePaymentModal } from './MakePaymentModal'
import { PaymentOrdersDashboardStats, PaymentOrdersDashboardStatsRefType } from './PaymentOrdersDashboardStats'
import { usePaymentOrdersDashboardStore } from './PaymentOrdersDashboardStore'
import { PaymentOrdersExport } from './PaymentOrdersExport'
import { getColumns, searchAndFilterOptions } from './PaymentOrdersTableConfig'
import { SetErrorModal } from './SetErrorModal'

const pageSize = 50
const defaultSorter: PaymentOrderSorter = { field: 'creationDate', order: SorterOrder.Descend }

export const PaymentOrdersDashboard: React.VFC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const intlPaymentOrders = useScopedIntl('payment_orders')
  const statsRef: MutableRefObject<PaymentOrdersDashboardStatsRefType> = useRef(null)
  const { user } = useContext(UserContext)
  const {
    setIsFetchingPaymentOrders,
    isSearching,
    setIsSearching,
    isMakePaymentsModalOpened,
    setIsMakePaymentsModalOpened,
    isErrorModalOpened,
    setIsErrorModalOpened,
    isMakingPayments,
    selectedPaymentOrders,
    setSelectedPaymentOrders,
    paymentOrders,
    onPaymentOrdersPageSuccess,
    allPaymentOrdersCount,
    setPaymentOrderToView,
    sorter,
    setSorter,
    currentPage,
    search,
    setSearch,
    filters,
    setFilters,
    isEverythingSelected,
    setIsEverythingSelected
  } = usePaymentOrdersDashboardStore()

  const [isFetchingCenters, setIsFetchingCenters] = useState(false)
  const [isFetchingStudies, setIsFetchingStudies] = useState(false)
  const [isFetchingVisits, setIsFetchingVisits] = useState(false)
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [studiesOptions, setStudiesOptions] = useState<DatacOption[]>([])
  const [visitsOptions, setVisitsOptions] = useState<DatacOption[]>([])
  const [isExportingSepa, setIsExportingSepa] = useState(false)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    localStorage.setItem(LocalStorageKey.LastFeature, Feature.Payments)
  }, [])

  const fetchCenterOptions = (search: string) => {
    if (!user.canAccess(AclFeature.Centers)) return
    setIsFetchingCenters(true)
    fetchCenters(
      { options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend }, search }, assignedToUser: true },
      {
        onSuccess: ({ centers }) => {
          setCentersOptions(centers.map(c => ({ value: c.id, label: c.abbreviation, sublabel: c.name })))
          setIsFetchingCenters(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingCenters(false)
        }
      }
    )
  }

  const fetchVisitOptions = (search: string) => {
    if (!user.canAccess(AclFeature.Recruitment)) return
    setIsFetchingVisits(true)
    fetchAllVisits(
      { options: { search, hasPaymentOrder: true } },
      {
        onSuccess: ({ visits }) => {
          setVisitsOptions(
            visits.map(({ id, title, scheduleName }) => ({
              value: id,
              label: title,
              sublabel: scheduleName
            }))
          )
          setIsFetchingVisits(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingVisits(false)
        }
      }
    )
  }

  const fetchStudiesOptions = (search: string) => {
    if (!user.canDo(AclFeature.StudyList)(AclAction.AccessAll)) return
    setIsFetchingStudies(true)
    fetchRecruitmentStudies(
      {
        options: {
          sorter: { order: SorterOrder.Descend, field: 'name' },
          offset: 0,
          search
        }
      },
      {
        onSuccess: studies => {
          setStudiesOptions(studies.map(({ reference, name }) => ({ value: reference, label: name })))
          setIsFetchingStudies(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingStudies(false)
        }
      }
    )
  }

  useEffect(() => {
    fetchPaymentOrdersPage(0, sorter)
    statsRef.current.triggerReloadKpis()
  }, [search, filters, currentPageSize])

  const onSearchChange = debounce((newPhrase: string) => {
    setSearch(newPhrase)
  }, 500)

  const createRecord = (paymentOrder: PaymentOrder) => ({ ...paymentOrder, key: paymentOrder.id })

  const refreshList = () => {
    fetchPaymentOrdersPage(currentPage - 1, sorter)
    statsRef.current.triggerReloadKpis()
  }

  const onMakePaymentsModalClose = () => {
    setIsMakePaymentsModalOpened(false)
    refreshList()
  }

  const onSetErrorModalClose = () => {
    setIsErrorModalOpened(false)
    refreshList()
  }

  const onBulkMakePayments = () => {
    const selectedPaymentOrdersObjects: TableRecord<PaymentOrder>[] = paymentOrders.filter(
      (paymentOrder: TableRecord<PaymentOrder>) => selectedPaymentOrders.includes(paymentOrder.id)
    )
    if (
      selectedPaymentOrdersObjects.some(
        (paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.status !== PaymentOrderStatus.Processing
      )
    ) {
      DatacMessage.error(
        intlPaymentOrders('make_payments.error.title'),
        intlPaymentOrders('make_payments.wrong_status.description')
      )
      return
    }
    if (
      selectedPaymentOrdersObjects.some(
        (paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.center !== selectedPaymentOrdersObjects[0].center
      )
    ) {
      DatacMessage.error(
        intlPaymentOrders('make_payments.error.title'),
        intlPaymentOrders('make_payments.wrong_center.description')
      )
      return
    }
    if (
      selectedPaymentOrdersObjects.some(
        (paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.currency !== selectedPaymentOrdersObjects[0].currency
      )
    ) {
      DatacMessage.error(
        intlPaymentOrders('make_payments.error.title'),
        intlPaymentOrders('make_payments.wrong_currency.description')
      )
      return
    }
    setIsMakePaymentsModalOpened(true)
  }

  const onSetError = (paymentOrderToSet?: PaymentOrder) => {
    if (paymentOrderToSet) {
      setSelectedPaymentOrders(
        paymentOrders
          .filter((paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.id === paymentOrderToSet.id)
          .map((paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.key)
      )
    }
    setIsErrorModalOpened(true)
  }

  const onExport = (paymentOrderToExport: PaymentOrder) => {
    setSelectedPaymentOrders(
      paymentOrders
        .filter((paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.id === paymentOrderToExport.id)
        .map((paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.key)
    )
    setIsExportModalOpened(true)
  }

  const onExportSepa = (paymentOrdersToExport: PaymentOrder[]) => {
    if (paymentOrdersToExport.some(paymentOrder => paymentOrder.status !== PaymentOrderStatus.Paid)) {
      DatacMessage.error(
        intlPaymentOrders('sepa_export.error.title'),
        intlPaymentOrders('sepa_export.error.status.description')
      )
      return
    }
    if (
      paymentOrdersToExport.some(paymentOrder => paymentOrder.transactionId !== paymentOrdersToExport[0].transactionId)
    ) {
      DatacMessage.error(
        intlPaymentOrders('sepa_export.error.title'),
        intlPaymentOrders('sepa_export.error.transaction.description')
      )
      return
    }
    setIsExportingSepa(true)
    exportSepa(
      { transactionId: paymentOrdersToExport[0].transactionId },
      {
        onSuccess: file => {
          setIsExportingSepa(false)
          downloadBlob(convertXMLStringToblob(file.data), file.filename)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsExportingSepa(false)
        }
      }
    )
  }

  const fetchPaymentOrdersPage = (page: number, sorter: PaymentOrderSorter) => {
    setSelectedPaymentOrders([])
    setIsFetchingPaymentOrders(true)
    return fetchPaymentOrders(
      {
        options: {
          search,
          filters,
          limit: currentPageSize,
          offset: page * currentPageSize,
          sorter: sorter || defaultSorter
        }
      },
      {
        onSuccess: ({ paymentOrders, allPaymentOrdersCount }) => {
          onPaymentOrdersPageSuccess({
            paymentOrders: paymentOrders.map(createRecord),
            allPaymentOrdersCount,
            currentPage: page + 1
          })
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingPaymentOrders(false)
        }
      }
    )
  }

  const onPageChange = (page: number) => {
    fetchPaymentOrdersPage(page - 1, sorter)
  }

  const columns = getColumns({
    columnNames: {
      fullName: intlPaymentOrders('field.full_name'),
      recruitmentReference: intlPaymentOrders('field.recruitment_reference'),
      creationDate: intlPaymentOrders('field.creation_date'),
      center: intlPaymentOrders('field.center_abbreviation'),
      visit: intlPaymentOrders('field.visit'),
      amount: intlPaymentOrders('field.amount'),
      status: intlPaymentOrders('field.status'),
      statusDetails: intlPaymentOrders('field.status_details'),
      paymentDate: intlPaymentOrders('field.payment_date'),
      transactionId: intlPaymentOrders('field.transaction_id'),
      actions: ''
    },
    onView: (paymentOrder: PaymentOrder) => {
      setPaymentOrderToView(paymentOrder)
    },
    onSetError: (paymentOrder: PaymentOrder) => {
      onSetError(paymentOrder)
    },
    onExport: (paymentOrder: PaymentOrder) => {
      onExport(paymentOrder)
    },
    canEdit: user.canDo(AclFeature.PaymentOrders)(AclAction.Edit),
    canExport: user.canDo(AclFeature.PaymentOrders)(AclAction.Export)
  })

  const onRowConfig = (paymentOrder: PaymentOrder) => ({
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement
      if (rowClickNoPropagateClasses.some(className => target.closest('div')?.classList.value.includes(className)))
        return

      setPaymentOrderToView(paymentOrder)
    }
  })

  const onSorterChange = (tableSorter: { field: keyof PaymentOrder; order: SorterOrder }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchPaymentOrdersPage(currentPage - 1, newSorter)
      setSorter(newSorter)
    }
  }

  const onFiltersChange = (filters: Record<string, string[]>) => {
    setIsSearching(true)
    setFilters(filters)
  }

  const onSelectAll = (allPages: boolean) => {
    setSelectedPaymentOrders(paymentOrders.map((paymentOrder: TableRecord<PaymentOrder>) => paymentOrder.key))
    setIsEverythingSelected(allPages)
  }

  const selectedCount = isEverythingSelected ? allPaymentOrdersCount : selectedPaymentOrders.length
  const selectedPaymentOrdersObjects: PaymentOrder[] = paymentOrders.filter((paymentOrder: TableRecord<PaymentOrder>) =>
    selectedPaymentOrders.includes(paymentOrder.id)
  )

  return (
    <RedirectNoAccessWrapper
      hasNoAccess={!user.isRecruitmentEnabled || !user.isPaymentsEnabled || !user.canAccess(AclFeature.PaymentOrders)}
      path="payment-orders"
    >
      <div className="payment-orders">
        <div className="payment-orders__header">
          <DatacTitle type="h1">{intlPaymentOrders('title')}</DatacTitle>
        </div>
        <div className="payment-orders-dashboard">
          <PaymentOrdersDashboardStats ref={statsRef} />
          <div className="payment-orders-dashboard__table">
            <DatacTableSearchAndFilters
              onSearchChange={value => onSearchChange(value)}
              isSearching={isSearching || isFetchingCenters || isFetchingStudies || isFetchingVisits}
              initialSearchValue={search}
              onFiltersChange={onFiltersChange}
              searchAndFilterOptions={searchAndFilterOptions(
                centersOptions,
                studiesOptions,
                visitsOptions,
                fetchCenterOptions,
                fetchStudiesOptions,
                fetchVisitOptions,
                intl
              )}
              initialFilterValues={filters}
              collapsibleSearch
              buttonType="ghost"
            />
            <DatacTable
              dataSource={paymentOrders}
              rowSelection={{
                type: 'checkbox',
                onChange: setSelectedPaymentOrders,
                selectedRowKeys: selectedPaymentOrders,
                selections: currentPageSize < allPaymentOrdersCount && [
                  {
                    key: 'all',
                    text: intlPaymentOrders('select_all', { count: paymentOrders.length }),
                    onSelect: () => onSelectAll(false)
                  },
                  {
                    key: 'all_pages',
                    text: intlPaymentOrders('select_all_pages', { count: allPaymentOrdersCount }),
                    onSelect: () => onSelectAll(true)
                  }
                ]
              }}
              columns={columns}
              scroll={{ x: 900, y: window.innerHeight - 200 }}
              onRow={onRowConfig}
              pagination={{
                current: currentPage,
                onChange: onPageChange,
                total: allPaymentOrdersCount,
                pageSize: currentPageSize
              }}
              setPageSize={setCurrentPageSize}
              onChange={(_, __, sorter) => onSorterChange(sorter as { field: keyof PaymentOrder; order: SorterOrder })}
            />

            <PaymentOrdersExport
              onClose={() => setIsExportModalOpened(false)}
              isOpened={isExportModalOpened}
              search={search}
              filters={filters}
              records={selectedPaymentOrders}
              isEverythingSelected={isEverythingSelected}
            />

            <MakePaymentModal onClose={onMakePaymentsModalClose} isOpened={isMakePaymentsModalOpened} />
            <SetErrorModal onClose={onSetErrorModalClose} isOpened={isErrorModalOpened} />
          </div>
        </div>
      </div>
      <DatacBulkActionsBar
        selectedCount={selectedCount}
        onClose={() => {
          setSelectedPaymentOrders([])
          setIsEverythingSelected(false)
        }}
        actions={[
          {
            label: intl('common.export'),
            icon: 'download',
            onClick: () => setIsExportModalOpened(true),
            hidden: !user.canDo(AclFeature.PaymentOrders)(AclAction.Export)
          },
          {
            label: intlPaymentOrders('export_sepa'),
            icon: 'download',
            onClick: () => {
              const selectedPaymentOrdersObjects: PaymentOrder[] = paymentOrders.filter(
                (paymentOrder: TableRecord<PaymentOrder>) => selectedPaymentOrders.includes(paymentOrder.id)
              )
              onExportSepa(selectedPaymentOrdersObjects)
            },
            isLoading: isExportingSepa,
            hidden:
              !user.canDo(AclFeature.PaymentOrders)(AclAction.Export) ||
              !selectedPaymentOrdersObjects.every(paymentOrder => paymentOrder.centerNeedsBankDetails)
          },
          {
            label: intlPaymentOrders('make_payments.title'),
            icon: 'dollar',
            onClick: onBulkMakePayments,
            isLoading: isMakingPayments,
            hidden: !user.canDo(AclFeature.PaymentOrders)(AclAction.Edit)
          },
          {
            label: intlPaymentOrders('set_error'),
            icon: 'xCircle',
            onClick: () => onSetError(),
            hidden:
              !user.canDo(AclFeature.PaymentOrders)(AclAction.Edit) ||
              !selectedPaymentOrdersObjects.every(paymentOrder => paymentOrder.status === PaymentOrderStatus.Paid)
          }
        ]}
      />
    </RedirectNoAccessWrapper>
  )
}
